/* --------------------------------- TOPBAR --------------------------------- */

.ant-layout-header {
    z-index: 13;
    position: relative;
    color: #FFFFFF;
    background: #59ad1d !important;
}

.top-bar-link {
    color: #FFFFFF !important;
    font-size: 29px;
    font-family: 'La Belle Aurore';
}

.top-bar-link:hover {
    color: #FFFFFF !important;
}

.top-bar-button {
    border-radius: 8px !important;
    border: none !important;
}

.user-name {
    color: #FFFFFF !important;
    padding-right: 20px !important;
    font-size: 16px !important;
    line-height: 12px !important;
    margin-bottom: 0 !important;
}


/* --------------------------------- SIDEBAR -------------------------------- */

.ant-menu {
    padding-top: 20px;
}

.ant-layout-sider,
.ant-layout-sider-trigger {
    background-color: #FFFFFF !important;
    color: #59ad1d !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background-color: #59ad1d !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected>.ant-menu-title-content>a {
    background-color: #94be2a !important;
    color: #FFFFFF !important;
}

.ant-menu-item::after {
    border-right: 3px solid #59ad1d !important;
}

/* -------------------------------- checkBox -------------------------------- */
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #59ad1d !important;
    border-color: #59ad1d !important;
}


/* ---------------------------------- link ---------------------------------- */

a {
    color: #3a3a3a !important;
}

a:hover {
    color: #59ad1d !important;
}