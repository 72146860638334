.login-page-layout {
    background-color: #FFFFFF;
    text-align: center;
}

.login-button {
    width: 100% !important;
    border-radius: 20px;
    font-weight: 700;
    border: none !important;
    color: #FFFFFF;
    background: #59ad1d !important;
}

.ant-form-item {
    padding-top: 20px;
}

#login-form {
    width: 300px;
}

#login-form p {
    margin-bottom: 30px;
}

.width {
    width: 150px !important;
}

#login-form .form-title {
    color: #59ad1d;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
}

.form-description {
    color: #94be2a;
    font-weight: bold;
}

.form-message {
    color: #3a3a3a;
    font-weight: bold;
}

.atgmo-logo {
    max-width: 200px;
    margin: 60px 0px;
}